<template>
  <div>
    <el-main class="sensor">
      <el-container class="shopList">
        <div style="padding-bottom: 20px">
          <el-form :inline="true" :model="mQuery" class="demo-form-inline">
            <el-col :span="4" class="mr10">
              <el-form-item size="medium">
                <el-input
                  placeholder="模式名称"
                  v-model="mQuery.keyword"
                  class="input-with-select"
                  clearable
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="1" class="tl mr10">
              <el-form-item size="medium">
                <el-button
                  type="primary"
                  @click="mSearch"
                  class="general_bgc general_border cfff"
                  >查询</el-button
                >
              </el-form-item>
            </el-col>
            <el-col :span="1" class="tl mr10">
              <el-form-item size="medium">
                <el-popover
                  placement="bottom"
                  width="500"
                  trigger="click"
                  v-model="createPopover"
                >
                  <div style="padding: 20px">
                    <el-form
                      style="width: 100%"
                      :model="createFormData"
                      ref="createForm"
                      label-width="100px"
                      :rules="createRule"
                    >
                      <el-form-item :label="'模式名称'" prop="name" required>
                        <div style="width: 80%">
                          <el-input v-model="createFormData.name"></el-input>
                        </div>
                      </el-form-item>
                      <el-form-item prop="companyId" required label="企业名称">
                        <el-autocomplete
                          placeholder="企业名称"
                          :validate-event="false"
                          v-model="createFormData.companyName"
                          :fetch-suggestions="querySearchAsync"
                          clearable
                          @clear="handleClear"
                          style="width: 80%"
                          @select="handleSelect"
                        >
                          <template slot-scope="{ item }">
                            <span class="name">{{ item.company_name }}</span>
                            <span
                              class="addr"
                              style="
                                font-size: 12px;
                                color: silver;
                                float: right;
                              "
                              >{{ item.credit_code }}</span
                            >
                          </template>
                        </el-autocomplete>
                      </el-form-item>
                      <el-form-item>
                        <div slot="label">
                          <el-select
                            v-model="createFormData.info.type"
                            placeholder="请选择"
                          >
                            <el-option
                              v-for="item in typeOptions"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                            >
                            </el-option>
                          </el-select>
                        </div>
                        <div
                          style="width: 80%"
                          v-if="createFormData.info.type === '温度'"
                        >
                          <el-slider
                            @change="(e) => sliderChange(e, 0)"
                            v-model="createFormData.info.value"
                            range
                            :min="-40"
                            :max="100"
                          >
                          </el-slider>
                        </div>
                      </el-form-item>
                    </el-form>
                    <div style="text-align: right; margin: 0">
                      <el-button size="mini" type="text" @click="closePopover"
                        >取消</el-button
                      >
                      <el-button type="primary" size="mini" @click="createModel"
                        >确定</el-button
                      >
                    </div>
                  </div>

                  <el-button type="success" slot="reference">新增</el-button>
                </el-popover>
              </el-form-item>
            </el-col>
          </el-form>
          <el-table :data="modelList" style="width: 100%" border>
            <el-table-column type="index" label="序号" width="55">
            </el-table-column>
            <el-table-column prop="modeName" label="模式名称" width="180">
            </el-table-column>
            <el-table-column prop="companyName" label="绑定企业" width="180">
            </el-table-column>
            <el-table-column prop="modeType" label="模式类型" width="180">
            </el-table-column>
            <el-table-column prop="minValue" label="最小值" width="180">
            </el-table-column>
            <el-table-column prop="maxValue" label="最大值" width="180">
            </el-table-column>
            <el-table-column label="操作" width="200">
              <template slot-scope="scope">
                <div class="df aic jca" style="padding: 0 20%">
                  <el-popover
                  @show="popoverShow(scope.row)"
                  placement="bottom"
                  width="500"
                  trigger="click"
                  v-model="scope.row.editPopover"
                >
                  <div style="padding: 20px">
                    <el-form
                      style="width: 100%"
                      ref="editForm"
                      v-model="editForm"
                      label-width="100px"
                    >
                      <el-form-item :label="'模式名称'" prop="editName">
                        <div style="width: 80%">
                          <el-input v-model="editForm.editName"></el-input>
                        </div>
                      </el-form-item>

                      <el-form-item
                        label="温度"
                        v-if="scope.row.modeType === '温度'"
                      >
                        <div style="width: 80%">
                          <el-slider
                            @change="(e) => sliderChange(e, 1)"
                            v-model="editForm.editValue"
                            range
                            :min="-40"
                            :max="100"
                          >
                          </el-slider>
                        </div>
                      </el-form-item>
                    </el-form>
                    <div style="text-align: right; margin: 0">
                      <el-button
                        size="mini"
                        type="text"
                        @click="closeEPopover(scope.row)"
                        >取消</el-button
                      >
                      <el-button
                        type="primary"
                        size="mini"
                        @click="modelEdit(scope.row)"
                        >确定</el-button
                      >
                    </div>
                  </div>
                  <el-button
                    type="text"
                    class="disib blue"
                    size="mini"
                    slot="reference"
                    >修改</el-button
                  >
                </el-popover>
                <el-button
                  type="text"
                  class="disib red"
                  size="mini"
                  @click="delModel(scope.row)"
                  >删除</el-button
                >
                </div>
              </template>
            </el-table-column>
          </el-table>

          <div class="i-page disib mt20 pagination-box">
            <el-pagination
              background
              :page-size="15"
              layout="total,prev, pager, next"
              :total="modelTotal"
              :current-page="mCurrentPage"
              @current-change="mCurrentChange"
            >
            </el-pagination>
          </div>
        </div>
      </el-container>
    </el-main>
  </div>
</template>

<script>
import axios from "../../../util/api";
import api from "../../../util/extra-api";
export default {
  created() {
    this.typeOptions = this.typeList.map((v) => {
      return {
        value: v,
        label: v,
      };
    });
    this.companyId = localStorage.getItem("company_id");
    this.getModelList();
  },
  data() {
    return {
      typeOptions: null,
      typeList: ["温度"],
      modelList: [],
      modelTypeOptions: [],
      editForm: {
        editName: "",
        companyName: "",
        companyId: null,
        editValue: [-20, 40],
      },
      modelTotal: 0,
      createPopover: false,
      mQuery: {
        keyword: "",
        page: 1,
        limit: 15,
        type: 0,
      },
      createFormData: {
        name: "",
        companyName: "",
        companyId: null,
        info: {
          type: "温度",
          value: [-20, 60],
        },
      },
      createRule: {
        name: [{ required: true, message: "请输入分类名称", trigger: "blur" }],
        companyId: [{ required: true, message: "请选择公司" }],
        editName: [
          { required: true, message: "请输入分类名称", trigger: "blur" },
        ],
      },
      mCurrentPage: 1,
      companyId: null,
      companyName: null,
      form3: {
        device_id: "",
        device_name: "",
        company_id: "",
        company_name: "",
        type: "",
        unit: "",
        max_value: "",
        min_value: "",
        pager_offset: "0",
        pager_openset: "10",
      },
    };
  },
  methods: {
    popoverShow(row) {
      this.editForm.editName = row.modeName;
      this.editForm.editValue = [
        row.minValue.slice(0, -1),
        row.maxValue.slice(0, -1),
      ];
      console.log(this.editForm.editValue);
    },
    closeEPopover(row) {
      row.row = false;
    },
    sliderChange(e, index) {
      if (index) {
        this.editForm.editValue = e;
      } else {
        this.createFormData.info.value = e;
      }
    },
    handleClear() {
      this.createFormData.companyId = null;
      this.createFormData.companyName = "";
    },
    closePopover() {
      this.$refs["createForm"].resetFields();
      this.createPopover = false;
      this.createFormData.name = "";
      this.createFormData.type = "温度";
      this.createFormData.companyName = "";
      this.createFormData.companyId = null;
      this.form3.company_name = "";
      this.createFormData.info.value = [-40, 100];
    },
    handleSelect(item) {
      this.createFormData.companyName = item.company_name;
      this.createFormData.companyId = item.company_id;
      console.log(item, this.createFormData);

      this.form3.company_id = item.company_id;
      this.form3.company_name = item.company_name;
    },
    //根据库房名称查询信息
    querySearchAsync(queryString, cb) {
      console.log(queryString);
      if (queryString) {
        this.form3.company_name = queryString;
        axios.get("/pc/company/signList/2", {company_name:this.form3.company_name}).then((response) => {
          let results = response.data.company_list;
          cb(results);
        });
      } else {
        cb([]);
      }
    },
    createModel() {
      console.log(this.createFormData);
      this.$refs["createForm"].validate((valid) => {
        if (valid) {
          let data = {
            modeName: this.createFormData.name,
            companyId: this.createFormData.companyId,
          };
          data.modeType = this.typeList.indexOf(this.createFormData.info.type);
          data.maxValue = this.createFormData.info.value[1];
          data.minValue = this.createFormData.info.value[0];
          api.post(`/v1/pc/deviceMode/save`, data).then((res) => {
            if (res.code == 200) {
              this.$refs["createForm"].resetFields();
              this.createPopover = false;
              this.createFormData.name = "";
              this.createFormData.type = "温度";
              this.createFormData.info.value = [-40, 100];

              this.createFormData.companyName = "";
              this.createFormData.companyId = null;
              this.form3.company_name = "";
              this.getModelList();
            }
          });
        } else {
          return false;
        }
      });
    },
    getModelList() {
      api
        .get(`/v1/pc/deviceMode/list`, this.mQuery)
        .then((res) => {
          if (res.code == 200) {
            this.modelTotal = res.total;
            this.modelList = res.data.map((v) => {
              if (v.modeType === 0) {
                v.minValue += "℃";
                v.maxValue += "℃";
              }
              v.editPopover = false;
              // console.log(v.modeType, 111);
              v.modeType = this.typeList[v.modeType];
              return v;
            });
          }
        });
    },
    modelEdit(row) {
      api
        .post(`/v1/pc/deviceMode/update`, {
          companyId: this.companyId,
          id: row.id,
          modeType: this.typeList.indexOf(row.modeType),
          modeName: this.editForm.editName,
          minValue: this.editForm.editValue[0],
          maxValue: this.editForm.editValue[1],
        })
        .then((res) => {
          if (res.code == 200) {
            this.mQuery.page = 1;
            this.mCurrentPage = 1;
            this.getModelList();
          }
        });
    },
    delModel(row) {
      api
        .post(`/v1/pc/deviceMode/delete`, {
          id: row.id,
        })
        .then((res) => {
          if (res.code == 200) {
            this.mQuery.page = 1;
            this.mCurrentPage = 1;
            this.getModelList();
          }
        });
    },
    mSearch() {
      this.mQuery.page = 1;
      this.mCurrentPage = 1;
      this.getModelList();
    },
    mCurrentChange(page) {
      this.mQuery.page = page;
      this.getModelList();
    },
  },
};
</script>

<style lang="scss">
.sensor {
  overflow-x: hidden;
  .add {
    display: flex;
    justify-content: space-between;
  }
}
/deep/ .el-table__empty-block {
  width: auto !important;
}
.jca{
  justify-content: space-around;
}
.aic{
  align-items: center;
}
</style>